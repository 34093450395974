import { useStaticQuery, graphql } from "gatsby"

export const useSitePages = () => {
  const { sitePages } = useStaticQuery(
    graphql`
      query SitePages {
        sitePages: allSitePage {
          edges {
            node {
              path
              pageContext
            }
          }
        }
      }
    `
  )

  return sitePages
}
