import React, { useState, useEffect, useRef } from 'react';
import Link from "./shared/link"
import { Col, Row } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import ShareLinks from "./shareLinks"
import { contentExists, getPathByNodeId, renderFormattedText } from "../utils/ug-utils"
import { useSitePages } from "../hooks/useSitePages"
import Dropdown from 'react-bootstrap/Dropdown';
import '../styles/learningHub.scss';
import { Modal, modalApi } from "./modal"
import ReactPlayer from "react-player"

const WebinarTeaser = ({ node }) => {
  const sitePages = useSitePages()
  const categories = node.relationships.field_webinar_category
  const imageData = node._webinar_card_image?.field_card_image?.relationships.field_media_image
  const image = {
    gatsbyImageData: imageData?.localFile.childImageSharp.gatsbyImageData,
    alt: imageData?.alt,
  }
  const fullText = renderFormattedText(node.body, false); // Full text without truncation  
  const truncatedText  = renderFormattedText(node.body, true, { length: 200 })
  const [showFullText, setShowFullText] = useState(false);
  const gatsbyImage = image?.gatsbyImageData && <GatsbyImage alt={image.alt || "Description of the image"} image={image.gatsbyImageData} />
  const renderedText = renderFormattedText(node.body, true, { length: 200 })
  const url = getPathByNodeId(node.id, sitePages)
  const duration = node.field_webinar_video_duration
  const [showPlayer, setShowPlayer] = useState(false);
  const playerRef = useRef(null);
  const videoUrl = node?.relationships?.field_event_video?.field_media_oembed_video ?? 'Fallback Value';

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      className="share-toggle"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      aria-label="Share webinar"
    >
      {children}
      <i title="Share" class="fas fa-share-square share" aria-hidden="true"></i>
    </span>
  ));

  const handlePlayClick = () => {
    setShowPlayer(true);
    modalApi.show(`modal-${videoUrl}`);
  };

  const handleClose = () => {
    setShowPlayer(false);
  };

  const toggleFullText = () => {
    setShowFullText(!showFullText);
  };


  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) handleClose(); // Close on ESC key
    };
    window.addEventListener('keydown', handleEsc);

    // Event listener for clicks
    const handleClickOutside = (event) => {
      if (playerRef.current && !playerRef.current.contains(event.target)) {
        handleClose(); // Check for outside click and hide player
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('keydown', handleEsc);
      document.removeEventListener('mousedown', handleClickOutside); // Cleanup
    };
  }, []);

  return (
    <Row key={node.id} className="webinar webinar-teaser" as="article">
      <Col md={4}>
      <div className="webinar-image-wrapper">
        <div onClick={handlePlayClick} style={{ cursor: 'pointer' }} aria-label={`Play webinar video titled ${node.title}`}>
            {gatsbyImage}
            <i className="fa-regular fa-circle-play"></i>
            {duration !== null && typeof duration !== 'undefined' && <div className="card_duration"><i className="fa-solid fa-clock" aria-hidden="true"></i>{` ${duration}m`} </div>}
        </div>
        {showPlayer && (
          <div className="popup-overlay" ref={playerRef}>
            <div className="popup-content">
              <Modal
                id={`modal-${videoUrl}`}
                showHeader={false}
                showFooter={false}
                contentClassName="video-modal"
                content={
                  <ReactPlayer id={node?.title} className="react-player" width="100%" url={videoUrl} playing={true} controls={true} />
                }
                centered
              />
            </div>
          </div>
        )}
      </div>
      </Col>
      <Col md={8}>
        <header>
        {categories?.map((category) =>
          category.name !== "General" ? (
            <span key={category.id}>{category.name}</span>
          ) : null
        )}
        </header>
        <h4 className="node-title" onClick={handlePlayClick} style={{ cursor: 'pointer' }}>{node.title}</h4>
        {!showFullText &&
          <span className="webinar-teaser-body truncated-text" onClick={toggleFullText}>
            {truncatedText}
          </span>
        }
        {showFullText &&
          <span className="webinar-teaser-body full-text" onClick={toggleFullText}>
            {fullText}
          </span>
        }
        <footer>
          <p className="watch-video node-title" onClick={handlePlayClick}>
            Watch Video <i className="fa-sharp fa-regular fa-circle-chevron-right"></i>
          </p>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              Share with a friend
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <ShareLinks url={url} mini />
            </Dropdown.Menu>
          </Dropdown>
        </footer>
      </Col>
    </Row>
  );
}

export default WebinarTeaser;
