import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { StaticImage,  } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { Col, Container, Row } from "react-bootstrap"
import "../components/fragments"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import ReactPaginate from 'react-paginate';
import WebinarTeaser from "../components/webinarTeaser"
import Form from 'react-bootstrap/Form'
import HeroSpotlight from "../components/heroSpotlight"
import Breadcrumbs from "../components/breadcrumbs"

const WebinarListPage = ({ data, pageContext }) => {
  const webinarsData = data.webinars.edges
  const webinarCategories = data.webinarCategories.edges.map((edge) => edge.node)
  const heroImage = <StaticImage src="../images/webinars-bg.jpg" alt="U of G Webinars" />
  const itemsPerPage = 6

  const [filters, setFilter] = useState({
    topics: [],
    duration: [],
    created: [],
  })
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const [list, setList] = useState(webinarsData)
  const [subset, setSubSet] = useState(list.slice(itemOffset, endOffset))
  const pageCount = Math.ceil(list.length / itemsPerPage);
  useEffect(() => {
    setList(webinarsData.filter((webinar) => {
      if (filters.topics.length > 0 && !webinar.node.relationships.field_webinar_category.some((topic) => (filters.topics.includes(topic.id)))) {
        return false;
      }
      let returnFlag = true;
      if (filters.duration.length > 0) {
        const duration = webinar.node.field_webinar_video_duration ? parseInt(webinar.node.field_webinar_video_duration) : 0;
        filters.duration.forEach(filter => {
          const range = filter.split('-');

          if (duration <= parseInt(range[0])) {
            returnFlag = false;
            return false;
          }
          if (duration > parseInt(range[1])) {
            returnFlag = false;
            return false;
          }
        });
        if (!returnFlag) {
          return false;
        }
      }
      if (filters.created.length > 0 ) {
        const lapsed = (Date.now()-Date.parse(webinar.node.created))/(3600*1000*24);
        filters.created.forEach(filter => {
          if (lapsed > filter) {
            returnFlag = false;
            return false;
          }
        });
        if (!returnFlag) {
          return false;
        }
      }
      return true;
    }))
  }, [filters, itemOffset])

  useEffect(() => {
    setSubSet(list.slice(itemOffset, endOffset))
  }, [list, itemOffset])

  const handleOnChangeTopics = (e) => {
    let newArray = filters.topics;
    if (e.target.checked) {
      newArray.push(e.target.id);
    }
    else {
      const index = newArray.indexOf(e.target.id);
      if (index !== -1) {
        newArray.splice(index, 1);
      }
    }
    setItemOffset(0)
    setFilter(previousState => {
      return { ...previousState, topics: newArray }
    });
  }

  const handleOnChangeDuration = (e) => {
    let newArray = filters.duration;
    if (e.target.checked) {
      newArray.push(e.target.id);
    }
    else {
      const index = newArray.indexOf(e.target.id);
      if (index !== -1) {
        newArray.splice(index, 1);
      }
    }
    setItemOffset(0)
    setFilter(previousState => {
      return { ...previousState, duration: newArray }
    });
  }

  const handleOnChangecreated = (e) => {
    let newArray = filters.created;
    if (e.target.checked) {
      newArray.push(e.target.id);
    }
    else {
      const index = newArray.indexOf(e.target.id);
      if (index !== -1) {
        newArray.splice(index, 1);
      }
    }
    setItemOffset(0)
    setFilter(previousState => {
      return { ...previousState, created: newArray }
    });
  }

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % list.length;
    setItemOffset(newOffset);
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const filterToggle = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: "webinar-list-page",
        }}
      />
      <Seo title={`Learning Hub`} keywords={[`alumni`, `webinars`, `learning`]} />

      {/**** Header and Title ****/}
      <div id="rotator" className="short-hero image">
        <Hero staticImage={heroImage} />
        <div>
          <HeroSpotlight>
            <h1 className="fancy-title">Learning Hub</h1>
          </HeroSpotlight>
        </div>
      </div>

      {/**** Body content ****/}
      <div className="page-container">
        <Container>
          <Breadcrumbs targetUrl="/learning-hub" activeTitle="Learning Hub" />
          <Row>
            <Col md={12}>
              <span
                href="#webinarFacets"
                id="filterButton"
                className="webinar-filter-button btn"
                tabIndex="0"
                aria-label="Filters"
                onClick={() => filterToggle()}
              >
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="512" height="512" x="0" y="0" viewBox="0 0 512 512" >
                  <path d="M16 133.612h260.513c7.186 29.034 33.45 50.627 64.673 50.627s57.487-21.593 64.673-50.627H496c8.836 0 16-7.164 16-16s-7.164-16-16-16h-90.142c-7.185-29.034-33.449-50.628-64.673-50.628s-57.488 21.594-64.673 50.628H16c-8.836 0-16 7.164-16 16s7.164 16 16 16zm325.186-50.628c19.094 0 34.628 15.534 34.628 34.627 0 19.094-15.534 34.628-34.628 34.628s-34.628-15.534-34.628-34.628c0-19.093 15.534-34.627 34.628-34.627zM16 272h90.142c7.186 29.034 33.449 50.627 64.673 50.627s57.487-21.593 64.673-50.627H496c8.836 0 16-7.164 16-16s-7.164-16-16-16H235.487c-7.186-29.034-33.449-50.628-64.673-50.628S113.327 210.966 106.141 240H16c-8.836 0-16 7.164-16 16s7.163 16 16 16zm154.814-50.628c19.094 0 34.628 15.534 34.628 34.628 0 19.093-15.534 34.627-34.628 34.627S136.186 275.093 136.186 256c0-19.094 15.534-34.628 34.628-34.628zM496 378.388h-90.142c-7.186-29.034-33.449-50.628-64.673-50.628s-57.487 21.594-64.673 50.628H16c-8.836 0-16 7.164-16 16s7.164 16 16 16h260.513c7.186 29.034 33.449 50.628 64.673 50.628s57.487-21.594 64.673-50.628H496c8.836 0 16-7.164 16-16s-7.163-16-16-16zm-154.814 50.628c-19.094 0-34.628-15.534-34.628-34.628s15.534-34.628 34.628-34.628 34.628 15.534 34.628 34.628-15.534 34.628-34.628 34.628z" fill="#000000" data-original="#000000" class=""></path>
                </svg> Filters
              </span>
            </Col>
          </Row>
          <Row className="row-with-vspace site-content">
            <Col md={4} lg={3} className="sidebar-left">
              <div id="webinarFacets"
                   className={`webinar-facets facets ${
                     isExpanded ? "is-expanded" : ""
                   }`}
                   aria-hidden={`${
                     isExpanded ? "false" : "true"
                   }`}
              >
                <Form>
                  <div className="webinars-topics facet">
                    <h4>Topic</h4>
                    {webinarCategories.map((topic) => (
                      <Form.Check
                        type='checkbox'
                        id={topic.id}
                        label={topic.name}
                        aria-label={topic.name}
                        onChange={handleOnChangeTopics}
                      />
                    ))}
                  </div>
                  <div className="webinars-duration facet">
                    <h4>Duration</h4>
                    {[{id: "0-30", name: "Less than 30 min"},
                      {id: "30-60", name: "30 min to 1 hr"},
                      {id: "60-120", name: "1 hr to 2 hr"},
                      {id: "120-10000", name: "Over 2 hr"}].map((item) => (
                      <Form.Check
                        type='checkbox'
                        id={item.id}
                        label={item.name}
                        aria-label={item.name}
                        onChange={handleOnChangeDuration}
                      />
                    ))}
                  </div>
                  <div className="webinars-created facet">
                    <h4>Date Posted</h4>
                    {[{id: 7, name: "This week"},
                      {id: 30, name: "This month"},
                      {id: 365, name: "This year"},
                      {id: 100000, name: "Over a year ago"}].map((item) => (
                      <Form.Check
                        type='checkbox'
                        id={item.id}
                        label={item.name}
                        aria-label={item.name}
                        onChange={handleOnChangecreated}
                      />
                    ))}
                  </div>
                </Form>
              </div>
            </Col>
            <Col md={8} lg={9} className="content-area">
              {/**** Body content ****/}
              <Container>
                {subset.length > 0 ? (
                  <>
                    {subset.map(({ node }, index) => {
                      return (
                        <WebinarTeaser node={node} />
                      )
                    })}
                  </>
                ) : (
                  <p>No events to display.</p>
                )}
              </Container>
              <nav aria-label="Page navigation">
              <ReactPaginate
                breakLabel="..."
                nextLabel={<span role="Next button">Next</span>}
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel={<span role="Previous button">Previous</span>}
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                activeClassName="active"
                aria-label="Pagination Navigation"
                previousLinkClassName="previous-link"
                nextLinkClassName="next-link"
              />
              </nav>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

WebinarListPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default WebinarListPage

export const query = graphql`
  {
    webinars: allNodeEvent(
      limit: 1000
      filter: { field_event_type: { in: ["virtual", "webinar"]}, field_event_video: {drupal_internal__target_id: {ne: null}}}
      sort: { fields: field_date___value, order: DESC }
    ) {
      edges {
        node {
          ...Webinar
          _webinar_card_image: relationships {
            field_card_image {
              ...CardImageWebinar
            }
          }
        }
      }
    }
    webinarCategories: allTaxonomyTermWebinarCategories(
      # Ensure there are nodes in Drupal with a webinar category set
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          ...TaxonomyTerm
        }
      }
    }
    webinarTags: allTaxonomyTermTags(
      # Ensure there are nodes in Drupal with tags set
      sort: { fields: name }
    ) {
      edges {
        node {
          ...TaxonomyTerm
        }
      }
    }
  }
`
